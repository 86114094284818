import React, { useState, useEffect } from "react";

import Navbar from "../components/Navbar";
import ContactUs from "../components/ContactUs";
import Sidebar from "../components/Sidebar";
import IntroSection from "../components/IntroSection";
import Service from '../components/ServiceSection';
import Partnership from "../components/PartnerSection";
import News from "../components/NewSection";
import Footer from '../components/FooterSection';
import MeetOurCompany from '../components/MeetOurCompany';

import axios from "axios";
import qs from "qs";

const serverUrl = process.env.REACT_APP_STRAPI_SERVER;
const token = process.env.REACT_APP_STRAPI_TOKEN;

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenContactUs, setisOpenContactUs] = useState(false);
  const [isArabic, setIsArabic] = useState(false);
  const [currentLocale, setCurrentLocale] = useState('en');

  const [services, setServices] = useState([]);
  const [news, setNews] = useState([]);
  const [config, setConfig] = useState([]);  

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const toggleContactUs = () => {
    setisOpenContactUs((prev) => !prev);
  };

  const query = qs.stringify({ 
    populate: '*',
    fields: '*',
    publicationState: 'live',
    locale: ['en'],
  }, {
    encodeValuesOnly: true, // prettify url
  });

  const axiosConfig = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const updateLocale = (newLocale) => {
    setCurrentLocale(newLocale);
    setIsArabic(newLocale === 'ar');
  };

  const getAllService = async () => {
    try {
      // fetch services from strapi
      const res = await axios.get(`${serverUrl}/api/services?${query}`,axiosConfig);
      // get result from nested object destructuring
      const {
        data: { data },
      } = res;
      setServices(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  const getAllNews = async () => {
    try {
      // fetch services from strapi
      const res = await axios.get(`${serverUrl}/api/news?${query}`,axiosConfig);
      // get result from nested object destructuring
      const {
        data: { data },
      } = res;
      setNews(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  const getAllConfig = async () => {
    try {
      // fetch services from strapi
      const res = await axios.get(`${serverUrl}/api/configs?${query}`,axiosConfig);
      // get result from nested object destructuring
      const {
        data: { data },
      } = res;
      setConfig(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    // get all services
    getAllService();
    // get all news
    getAllNews();
    // get all config
    getAllConfig();

  }, []);
  
  return (
    <div className="flex justify-center bg-gray-100">
      <div className="w-full items-center">
        <Sidebar isArabic={isArabic} isOpen={isOpen} toggle={toggleMenu} bgColor="#3E5D87" email="welcome@oceangroup.global" toggleContactUs={toggleContactUs} config={config} currentLanguage={currentLocale}/>
        <ContactUs isArabic={isArabic} isOpenContactUs={isOpenContactUs} toggle={toggleContactUs} bgColor="#3E5D87" email="welcome@oceangroup.global"/>
        <Navbar isArabic={isArabic} toggleMenu={toggleMenu} toggleContactUs={toggleContactUs} isMain={true} config={config}/>
        <IntroSection isArabic={isArabic} />
        <Service isArabic={isArabic} services={services} />
        {/* <Partnership isArabic={isArabic} config={config} /> */}
        {news.length > 0 ? (
          <News isArabic={isArabic} news={news}/>
          ) : ('')
          }
        
        <Footer color="bg-blue-450" toggleContactUs={toggleContactUs} isDetail={false} config={config} isArabic={isArabic} currentLocale={currentLocale} updateLocale={updateLocale}/>
        <MeetOurCompany />
      </div>
    </div>
  );
}

export default Home;
